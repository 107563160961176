import React from "react";
import './Inicio.scss'
import CardView from "../../components/molecules/CardView/CardView";
import CardPlan from "../../components/atoms/CardPlan/CardPlan";
import GraficaBarras from "../../components/organisms/Charts/GraficaBarras/GraficaBarras";
import GraficaLineas from "../../components/organisms/Charts/GraficaLineas/GraficaLineas";
import GraficaArea from "../../components/organisms/Charts/GraficaArea/GraficaArea";
import GraficaPastel from "../../components/organisms/Charts/GraficaPastel/GraficaPastel";

const Inicio = () => {
    return (
        <CardView>
            <div className="Indicators">
                <h1 className="in-title">Mis Gráficas</h1>
                <div className="parent">
                    <div className="child1">
                        <div className="precios">
                            <CardPlan
                                title="NEGOCIOS ACTIVOS"
                                children={300}
                                numberColor="#95DEF4"
                            ></CardPlan>
                            <CardPlan
                                title="PAGOS PROMEDIO"
                                children={250}
                                numberColor="#8794FD"
                            ></CardPlan>
                        </div>
                        <div className="graficas">
                            <CardPlan
                                height="300px"
                                title="GRÁFICA COMPARATIVA DE NEGOCIOS ACTIVOS ONTRA NEGOCIOS INACTIVOS POR AÑOS"
                                children={""}
                                numberColor="#8794FD"
                            >
                                <GraficaBarras></GraficaBarras>
                            </CardPlan>
                            <CardPlan
                                height="300px"
                                title="GRÁFICA DE HISTÓRICO ANUAL DE FACTURACIÓN, MOSTRANDO EL ACUMULADO DE AÑOS ANTERIORES"
                                children={""}
                                numberColor="#8794FD"
                            >
                                <GraficaLineas></GraficaLineas>
                            </CardPlan>
                        </div>
                    </div>
                    <div className="child2">
                        <div className="precios">
                            <CardPlan
                                title="NEGOCIOS INACTIVOS"
                                children={100}
                                numberColor="#35A8E0"
                            >
                                {" "}
                            </CardPlan>
                            <CardPlan
                                title="PORCENTAJE CRECIMIENTO ANUAL"
                                children={500}
                                numberColor="#6F7AB9"
                            ></CardPlan>
                        </div>
                        <div className="graficas">
                            <CardPlan
                                height="300px"
                                title="GRÁFICA COMPARATIVA DE NEGOCIOS ACTIVOS E INACTIVOS AL CORRER DE LOS MESES, MOSTRANDO ACUMULADO"
                                children={""}
                                numberColor="#8794FD"
                            >
                                <GraficaArea></GraficaArea>
                            </CardPlan>
                            <CardPlan
                                height="300px"
                                title="GRÁFICA DE HISTÓRICO MENSUAL DE FACTURACIÓN, MOSTRANDO ACUMULADO DE MESES ANTERIORES"
                                children={""}
                                numberColor="#35A8E0"
                            >
                                <GraficaPastel></GraficaPastel>
                            </CardPlan>
                        </div>
                    </div>
                </div>
            </div>
        </CardView>
    );
};

export default Inicio;
