import React, { useState } from "react";
import "./Manuals.scss";
import manuales from "../../assets/icons/icon_menu_manuales_inact.png";
import ManualCategoryCircle from "../../components/atoms/ManualCategoryCircle/ManualCategoryCircle";
import Select from "../../components/atoms/Select/Select";
import Button from "../../components/atoms/Button/Button";
import CardView from "../../components/molecules/CardView/CardView";
import Input from "../../components/atoms/Input/Input";
import buscarIcon from '../../assets/icons/BUSCAR.png'
import SeccionManuales from "./SeccionManuales";
const Manuals = () => {
    const [selectValue, setSelectValue] = useState();
    const selectOptions = [
        { name: "opcion1", code: 1 },
        { name: "opcion2", code: 2 },
        { name: "opcion3", code: 3 },
        { name: "opcion4", code: 4 },
    ];
    const [seccion, setSeccion] = useState(null)
    console.log("selectValue", selectValue);
    return (
        <CardView>
            <div className="Manuals">
                <div className="buscador">
                    <div className="contenedor">
                        <Input text={"Buscar"} icon={buscarIcon}></Input>
                    </div>
                </div>
                <div className="containerCategories">
                    <ManualCategoryCircle
                        img={manuales}
                        title="MANUALES"
                        onClick={() => { setSeccion('manual') }}
                    ></ManualCategoryCircle>
                    <ManualCategoryCircle
                        img={manuales}
                        title="FAQ"
                        onClick={() => { setSeccion('faq') }}
                    ></ManualCategoryCircle>
                    <ManualCategoryCircle
                        img={manuales}
                        title="VIDEOS"
                        onClick={() => { setSeccion('videos') }}
                    ></ManualCategoryCircle>
                    <ManualCategoryCircle
                        img={manuales}
                        title="COMO PAGAR MEMBRESIAS"
                        onClick={() => { setSeccion('membresias') }}
                    ></ManualCategoryCircle>
                    <ManualCategoryCircle
                        img={manuales}
                        title="ATENCIÓN PERSONALIZADA"
                        onClick={() => { setSeccion('atencion') }}
                    ></ManualCategoryCircle>
                </div>
                {
                    seccion == null ?
                        <>
                            <div className="createCategory">
                                <div className="card">
                                    <div className="title">CREAR CATEGORÍA</div>
                                    <div className="select-container">
                                        <Select
                                            className="select"
                                            setValue={setSelectValue}
                                            value={selectValue}
                                            options={selectOptions}
                                        ></Select>
                                    </div>
                                    <div className="buttonCrear">
                                        <Button
                                            className="degrad buttoncREAR"
                                            text={"CREAR"}
                                        ></Button>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        seccion === 'manual' ?
                            <>
                                <div className="manualesContainer">
                                    <div className="titulo">Manuales</div>
                                    <SeccionManuales></SeccionManuales>

                                </div>
                            </>
                            :
                            seccion === null ?
                                <></>
                                :
                                seccion === null ?
                                    <></>
                                    :
                                    seccion === null ?
                                        <></>
                                        :
                                        seccion === null ?
                                            <></>
                                            :
                                            seccion === null ?
                                                <></>
                                                :
                                                seccion === null ?
                                                    <></>
                                                    :
                                                    ""

                }

            </div>
        </CardView>
    );
};
export default Manuals;
