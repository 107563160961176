import React from "react";
import ManualesCard from "../../components/atoms/ManualesCard/ManualesCard";

const SeccionManuales = () => {
    const data = [
        {
            titulo: "Crea tu cuenta en APP Restaurant",
            texto: "Registra tu cuenta para poder usar el app de Restaurant.",
            manual: "pdf"
        },
        {
            titulo: "Agrega un negocio",
            texto: "Agrega un nuevo negocio a tu cuenta",
            manual: "pdf"
        },
        {
            titulo: "Crea tu menú / catalogo ",
            texto: "Crea la lista de artículos organizados por grupos",
            manual: "pdf"
        },
        {
            titulo: "Agregar una sucursal y mostradores",
            texto: "Agregar una nueva sucursal a un negocio y crea mostradores",
            manual: "pdf"
        },
        {
            titulo: "Asociar una terminal a la sucursal",
            texto: "Obtener el código de la sucursal para asociar una terminal a la sucursal.",
            manual: "pdf"
        },
        {
            titulo: "Liga un Menu / Catalogo a tu sucursal",
            texto: "Liga la lista de articulos que se venderan en una sucursal.",
            manual: "pdf"
        },
        {
            titulo: "Registrar Tarjeta de Debito o credito",
            texto: "Registrar la tarjeta de credito donde se cobrara la mens.",
            manual: "pdf"
        }
    ]
    return (
        <div className="SeccionManuales">
            {
                data.map((item,key)=>{
                    return(
                        <>
                            <ManualesCard titulo={item.titulo} text={item.texto}></ManualesCard>
                        </>
                    )
                })
            }
        </div>
    )

}
export default SeccionManuales