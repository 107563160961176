import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import CrearNuevoNegocio from "../../pages/CrearNuevoNegocio/CrearNuevoNegocio";
import ListPlans from "../../pages/ListPlans/ListPlans";
import Layout from "../../components/organisms/Layout/Layout";
import BusinessListing from "../../pages/BusinessListing/BusinessListing";
import BusinessPayments from "../../pages/BusinessPayments/BusinessPayments";
import BusinessReport from "../../pages/BusinessReport/BusinessReport";
import BusinessDefeated from "../../pages/BusinessDefeated/BusinessDefeated";
// import Indicators from "../../pages/Indicators/Indicators";
import AvisosAutomaticos from "../../pages/AvisosAutomaticos/AvisosAutomaticos";
import Manuals from "../../pages/Manuals/Manuals";
import Inicio from "../../pages/Inicio/Inicio";

const RoutesAuth = () => {
    return (
        <Layout>
            <Routes>
                <Route
                    path="/inicio"
                    element={
                        <Suspense fallback={<div>Inicio</div>}>
                            <Inicio />
                        </Suspense>
                    }
                />
                <Route
                    path="/crear-nuevo-negocio"
                    element={
                        <Suspense fallback={<div>Skeleton</div>}>
                            <CrearNuevoNegocio></CrearNuevoNegocio>
                        </Suspense>
                    }
                />
                <Route
                    path="/list-plans"
                    element={
                        <Suspense fallback={<div>Skeleton</div>}>
                            <ListPlans />
                        </Suspense>
                    }
                />
                <Route
                    path="/business-list"
                    element={
                        <Suspense fallback={<div>Skeleton</div>}>
                            <BusinessListing />
                        </Suspense>
                    }
                />
                <Route
                    path="/business-payments"
                    element={
                        <Suspense fallback={<div>Skeleton</div>}>
                            <BusinessPayments />
                        </Suspense>
                    }
                />
                <Route
                    path="/business-report"
                    element={
                        <Suspense fallback={<div>Skeleton</div>}>
                            <BusinessReport />
                        </Suspense>
                    }
                />
                <Route
                    path="/reporte-de-negocios-vencidos"
                    element={
                        <Suspense fallback={<div>Skeleton</div>}>
                            <BusinessDefeated />
                        </Suspense>
                    }
                />
                {/* <Route
                    path="/indicadores"
                    element={
                        <Suspense fallback={<div>Skeleton</div>}>
                            <Indicators/>
                        </Suspense>
                    }
                /> */}
                <Route
                    path="/avisos-aoutomaticos"
                    element={
                        <Suspense fallback={<div>Skeleton</div>}>
                            <AvisosAutomaticos />
                        </Suspense>
                    }
                />
                <Route
                    path="/ayuda"
                    element={
                        <Suspense fallback={<div>Skeleton</div>}>
                            <Manuals />
                        </Suspense>
                    }
                />
            </Routes>
        </Layout>
    );
};
export default RoutesAuth;
