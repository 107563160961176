import React from "react";
import './ManualesCard.scss'


const ManualesCard = ({titulo,text,manual})=>
{
    return(
        <div className="ManualesCard">
            <div className="titulo">{titulo}</div>
            <div className="text">{text}</div>
            <div className="abrir-manual">Abrir el manual</div>
        </div>
    )
}
export default ManualesCard