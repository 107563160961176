import React from "react";
import "./ManualCategoryCircle.scss";

const ManualCategoryCircle = ({ img = "", url = "", title = "",onClick }) => {
    
    return (
        <div className="ManualCategoryCircle-container">
            <div
                className="ManualCategoryCircle"
                onClick={onClick}
            >
                <img src={img} alt=""></img>
            </div>
            <div className="title">{title}</div>
        </div>
    );
};
export default ManualCategoryCircle;
